import React from 'react';
import iconHanley from './hanley.jpg';
import iconMmcb from './mmcb.png';
import iconOsc from './osc.png';
import css from './CompaniesLogs.module.css';

const CompaniesLogs = () => {
  const logos = [
    {
      icon: iconHanley,
      link: 'https://www.thehanley.co.uk/',
    },
    {
      icon: iconOsc,
      link: 'https://www.oscadvice.co.uk/',
    },
    {
      icon: iconMmcb,
      link: 'https://www.mmcbuild.co.uk/',
    },
  ];
  return (
    <div className={css.wrapper}>
      <div className={css.logosBox}>
        <p className={css.title}>
          <strong>Trusted by Many Suppliers and Contractors Nationwide</strong>
          <br />
          <span>Our Partners</span>
        </p>
        {logos.map(i => (
          <a href={i.link} target="_blank" className={css.imageWrapper}>
            <img src={i.icon} className={css.logo} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default CompaniesLogs;
