import React from 'react';
import homePic from './homePic.png';
import homePicMobile from './homePicMobile.png';
import checkIcon from './check.png';
import css from './BannerSection2.module.css';
import { Button } from '../../../../components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const BannerSection2 = () => {
  const history = useHistory();
  return (
    <div className={css.wrapper}>
      <div className={css.heroLeft}>
        <h1 className={css.title}>
          We <strong>guide</strong> you from the first step of your self build journey
        </h1>
        <Button className={css.cta} type="button" onClick={() => history.push('/how-it-works')}>
          How it works
        </Button>
        {/* <div className={css.checksSection}>
          <div className={css.checkItem1}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Navigate the self-build process</span>
          </div>
          <div className={css.checkItem2}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Control your costs</span>
          </div>
          <div className={css.checkItem1}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Connect with trusted suppliers</span>
          </div>
          <div className={css.checkItem2}>
            <img className={css.checkIcon} src={checkIcon} />
            <span>Manage your project</span>
          </div>
        </div> */}
      </div>
      <img src={homePic} className={css.housePic} />
      <img src={homePicMobile} className={css.homePicMobile} />
    </div>
  );
};

export default BannerSection2;
